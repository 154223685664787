import { addEventListener } from 'o365.vue.composables.EventListener.ts';

export default class EdgeScroller {
    private _tickInterval: number | null = null;

    private _scrollHor: boolean;
    private _scrollVert: boolean;

    private _tickLeft: boolean = false;
    private _tickRight: boolean = false;
    private _tickUp: boolean = false;
    private _tickDown: boolean = false;

    private _scrollContainer: HTMLElement;
    private _scrollByTick: number;

    private _shouldSkipVerticalScroll: () => boolean;
    private _shouldSkipHorizontalScroll: () => boolean;

    private _onScrollCallback: (() => void) | null = null;

    /**
     * TO DO used to implement quicker scrolling after some delay
     */
    //private _tickCount: number = 0;

    private _enabled: boolean = false;
    private _scrollEvent!: Function;
    private _mouseupEvent!: Function;

    constructor(pOptions: {
        scrollContainer: HTMLElement,
        scrollAxis: 'x' | 'y' | 'xy',
        scrollByTick?: number,
        shouldSkipVerticalScroll?: () => boolean,
        shouldSkipHorizontalScroll?: () => boolean,
        onScrollCallback?: () => void
    }) {
        this._scrollContainer = pOptions.scrollContainer;
        this._scrollHor = pOptions.scrollAxis.indexOf('x') !== -1;
        this._scrollVert = pOptions.scrollAxis.indexOf('y') !== -1;

        this._scrollByTick = pOptions.scrollByTick !== undefined ? pOptions.scrollByTick : 10;

        if (pOptions.onScrollCallback) {
            this._onScrollCallback = pOptions.onScrollCallback;
        }

        this._shouldSkipVerticalScroll = pOptions.shouldSkipVerticalScroll || (() => false);
        this._shouldSkipHorizontalScroll = pOptions.shouldSkipHorizontalScroll || (() => false);
    }

    public check(e: MouseEvent): void {
        const skipVerticalScroll = this._shouldSkipVerticalScroll();

        if (skipVerticalScroll || this._shouldSkipHorizontalScroll()) { return; }

        const rect = this._scrollContainer.getBoundingClientRect();
        const scrollTick = this._scrollByTick;

        this._tickLeft = e.clientX < (rect.left + scrollTick);
        this._tickRight = e.clientX > (rect.right - scrollTick);
        this._tickUp = e.clientY < (rect.top + scrollTick) && !skipVerticalScroll;
        this._tickDown = e.clientY > (rect.bottom - scrollTick) && !skipVerticalScroll;
        if (this._tickLeft || this._tickRight || this._tickUp || this._tickDown) {
            this.ensureTickingStarted();
        } else {
            this.ensureCleared();
        }
    }

    private ensureTickingStarted(): void {
        if (this._tickInterval === null) {
            this._tickInterval = window.setInterval(this.doTick.bind(this), 10);
            //this._tickCount = 0;
        }
    }

    private doTick(): void {
        //this._tickCount++;

        if (this._scrollVert) {
            const vScrollPosition = this.getVerticalPosition();
            if (this._tickUp) {
                this.setVerticalPosition(vScrollPosition - this._scrollByTick);
            }

            if (this._tickDown) {
                this.setVerticalPosition(vScrollPosition + this._scrollByTick);
            }
        }

        if (this._scrollHor) {
            const hScrollPosition = this.getHorizontalPosition();
            if (this._tickLeft) {
                this.setHorizontalPosition(hScrollPosition - this._scrollByTick);
            }

            if (this._tickRight) {
                this.setHorizontalPosition(hScrollPosition + this._scrollByTick);
            }
        }

        if (this._onScrollCallback) {
            this._onScrollCallback();
        }
    }

    private getVerticalPosition(): number {
        return this._scrollContainer.scrollTop;
    }

    private setVerticalPosition(position: number): void {
        this._scrollContainer.scrollTop = position;
    }

    private getHorizontalPosition(): number {
        return this._scrollContainer.scrollLeft;
    }

    private setHorizontalPosition(position: number): void {
        this._scrollContainer.scrollLeft = position;
    }

    private ensureCleared(): void {
        if (this._tickInterval) {
            window.clearInterval(this._tickInterval);
            this._tickInterval = null;
        }
    }

    private handleMouseMove = (e: MouseEvent) => this.check(e);

    private handleMouseUp = () => {
        this._scrollEvent();
        this._mouseupEvent();
        this.ensureCleared(); 
        this._enabled = false;
    }

    public enable() {
        if (this._scrollContainer && !this._enabled) {
            this._scrollEvent = addEventListener(this._scrollContainer, 'mousemove', this.handleMouseMove, { passive: true });
            const parentDiv = document.querySelector('.app-container.container-scope') as HTMLElement ?? document.querySelector('[data-v-app]') as HTMLElement;
            this._mouseupEvent = addEventListener(parentDiv!, 'mouseup', this.handleMouseUp, { passive: true });
            this._enabled = true
        }
    }

    public disable() {
        if (this._scrollContainer && this._enabled) {
           this._scrollEvent();
           this._mouseupEvent();
           this.ensureCleared();
        }
    }

}